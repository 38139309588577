import { useEffect } from 'react';

import localFont from '@next/font/local';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { PageProps } from '@hultafors/hultaforsgroup/types';

export { reportWebVitals } from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/hultaforsgroup/context').then((mod) => mod.AppProvider),
);
const Footer = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Footer),
);
const GlobalStyle = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.GlobalStyle),
);
const Nav = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Nav),
);

const fontFranklinGothic = localFont({
  src: [
    {
      path: '../fonts/FranklinGothic-Book.woff',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../fonts/FranklinGothic-Medium.woff',
      weight: '400',
      style: 'bold',
    },
  ],
  variable: '--font-franklin-gothic',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const { global, settings, menu, featureToggle, footer, ...restProps }
    = pageProps;
  const isCampaignPage = router.asPath.startsWith('/campaign');

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>
        {`
        html,
        input,
        textarea,
        button,
        select {
          font-family: ${fontFranklinGothic.style.fontFamily};
        }
      `}
      </style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <GlobalStyle />
      <AppProvider value={{ settings, global, menu, featureToggle, footer }}>
        {!isCampaignPage && <Nav />}
        <Component {...restProps} />
        <GoogleTagManager gtmId="GTM-PL8PKQ" />
        {!isCampaignPage && <Footer />}
        <div id="portal"></div>
      </AppProvider>
    </>
  );
};

export default CustomApp;
